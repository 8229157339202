import React, {useEffect, useRef, useState, FC} from "react";
import styles from './FaqBlock.module.scss'
import cn from 'classnames'
import useWindowDimensions from "../../../customHooks/useWindowDimension";

type FaqBlockProps = {
    containerScaling?: string
    errorPage?: boolean
}

const FaqBlock:FC<FaqBlockProps> = ({containerScaling, errorPage}) => {

    const [open, setOpen] = useState<number | null>(null)
    const [scrollHeight, setScrollHeight] = useState(0)
    const [mainScroll, setMainScroll] = useState(0)
    const questionRef = useRef<null | HTMLDivElement>(null)
    const { width } = useWindowDimensions()
    const [openMore, setOpenMore] = useState(false)

    const onOpenHandler = (currentPanel: any) => {
        if (open === currentPanel) {
            setOpen(null)
            setScrollHeight(0)
        } else if (questionRef.current) {
            setScrollHeight(questionRef.current?.children[currentPanel].scrollHeight)
            setOpen(currentPanel)
        }
    }

    useEffect(() => {
        if (questionRef.current) {
            setMainScroll(questionRef.current?.scrollHeight)
        }
    }, [])

    return (
        <section className={cn(styles.container, containerScaling, {[styles.containerError]: errorPage})}>
            {errorPage ? null
            : <p className={styles.subtext}>We have tried to answer the most popular questions in advance</p>
            }
            <h2 className={styles.header}><span className={styles.bold}>Frequently Asked Questions</span></h2>

            <div className={styles.containerQuestion} ref={questionRef}>
                <div
                    className={`${styles.buttonWrapper} ${
                        open === 1 ? styles.clicked : null
                    }`}
                    onClick={() => onOpenHandler(1)}
                >
                    <p className={styles.buttonText}>
                        How can I become a member of the Vitaliv Club?
                    </p>
                    <div
                        className={`${styles.button} ${open === 1 && styles.clicked}`}
                    ></div>
                </div>
                <div
                    className={`${styles.panel} ${open === 1 ? styles.opened : ''}`}
                    style={{
                        height: open === 1 ? `${scrollHeight}px` : '0px',
                    }}
                >
                    <p className={styles.text}>
                        We invite you to <span className={styles.bold}>try out a one-month membership
                        to Vitaliv Club FOR FREE!</span> To activate your trial period, just make your first order.
                    </p>

                    <p className={styles.text}>
                        <span className={styles.bold}>
                            Activate your trial membership by completing your first order:
                        </span>
                    </p>

                    <p className={cn(styles.text, styles.textMargin)}>Choose any products and put as many packs as you want into the cart.
                        The free trial period will be included in your order list.
                    </p>
                    <p className={cn(styles.text, styles.textMargin)}>During your trial membership, <span className={styles.bold}>place as many
                        orders as you like.</span> There are no hidden or automatic payments for products or memberships!
                        Pay only for the products you&apos;ve ordered.</p>
                    <p className={styles.text}>
                        <span className={styles.bold}>An annual membership to Vitaliv Club costs
                            $49</span> (that’s <span className={styles.bold}>just $4 per month!</span>).
                        You can pay for your annual membership by placing your first order, at the end of your trial period.
                    </p>
                </div>
                <div
                    className={`${styles.buttonWrapper} ${
                        open === 3 ? styles.clicked : null
                    }`}
                    onClick={() => onOpenHandler(3)}
                >
                    <p className={styles.buttonText}>
                        Why should I pay for the membership?
                    </p>
                    <div
                        className={`${styles.button} ${open === 3 && styles.clicked}`}
                    ></div>
                </div>
                <div
                    className={`${styles.panel} ${open === 3 ? styles.opened : ''}`}
                    style={{
                        height: open === 3 ? `${scrollHeight}px` : '0px',
                    }}
                >
                    <p className={styles.text}>
                        At Vitaliv Club, you only pay for nutrition and <span className={styles.bold}>save up to 40% of
                        the products&apos; market price.</span>
                        You can easily budget the whole family&apos;s health by purchasing all at once
                        with <span className={styles.bold}>significant savings</span> and afford even more with
                        the <span className={styles.bold}>Vitaliv cashback system.</span><br/>
                        By using all of Vitaliv Club’s features, you can <span className={styles.bold}>save up to
                        several hundred dollars a year</span> on premium-quality dietary supplements.
                    </p>
                    <p className={styles.text}>
                        Vitaliv Club&apos;s fee allows a <span className={styles.bold}>balance between Club smart prices
                        and high quality</span>,
                        as well as <span className={styles.bold}>the great product line</span> and development
                        of <span className={styles.bold}>handy services</span>.
                    </p>
                    <p className={styles.text}>
                        By paying the membership fee you support the Vitaliv team involved in our
                        products&apos; R&D and production.<br/>
                        Our experienced nutritionists and specialists in the field of healthy longevity ensure that
                        only <span className={styles.bold}>tested formulas</span> and <span className={styles.bold}>carefully
                        selected specialized ingredients</span> are used.<br/>
                        Our experts in dietary supplements production work with only the best manufacturers who use
                        advanced technologies in strict compliance with the <span className={styles.bold}>highest
                        international quality standards</span> in all stages of production.
                    </p>
                </div>
                <div
                    className={`${styles.buttonWrapper} ${
                        open === 5 ? styles.clicked : null
                    }`}
                    onClick={() => onOpenHandler(5)}
                >
                    <p className={styles.buttonText}>
                        Can I return the products? What if I don&apos;t like something?
                    </p>
                    <div
                        className={`${styles.button} ${open === 5 && styles.clicked}`}
                    ></div>
                </div>
                <div
                    className={`${styles.panel} ${open === 5 ? styles.opened : ''}`}
                    style={{
                        height: open === 5 ? `${scrollHeight}px` : '0px',
                    }}
                >
                    <p className={styles.text}>
                        If you have purchased Vitaliv products and are not satisfied for any reason,
                        you can <span className={styles.bold}>return them within 14 days and
                        receive full compensation.</span><br/>
                        Get in touch with us and we will take care of everything.
                    </p>
                    <p className={styles.text}>
                        <span className={styles.bold}>Please note</span> that you can return the products
                        only if the packs were not opened and the packaging is left intact.<br/>
                        Also, please note that we don&apos;t compensate for the cost of delivery to return the products.
                    </p>
                </div>
                <div
                    className={`${styles.buttonWrapper} ${
                        open === 7 ? styles.clicked : null
                    }`}
                    onClick={() => onOpenHandler(7)}
                >
                    <p className={styles.buttonText}>
                        Can I cancel my membership?
                    </p>
                    <div
                        className={`${styles.button} ${open === 7 && styles.clicked}`}
                    ></div>
                </div>
                <div
                    className={`${styles.panel} ${open === 7 ? styles.opened : ''}`}
                    style={{
                        height: open === 7 ? `${scrollHeight}px` : '0px',
                    }}
                >
                    <p className={styles.text}>
                        You can cancel your Vitaliv Club membership by simply not buying our products.
                    </p>
                    <p className={styles.text}>
                        When your current membership expires, your access to the products will be restricted.<br/>
                        Please note that we do not offer refunds for the current membership.
                    </p>
                </div>
                <div
                    className={`${styles.buttonWrapper} ${
                        open === 9 ? styles.clicked : null
                    }`}
                    onClick={() => onOpenHandler(9)}
                >
                    <p className={styles.buttonText}>
                        How does the membership work?
                    </p>
                    <div
                        className={`${styles.button} ${
                            open === 9 ? styles.clicked : null
                        }`}
                    ></div>
                </div>
                <div
                    className={`${styles.panel} ${open === 9 ? styles.opened : ''}`}
                    style={{
                        height: open === 9 ? `${scrollHeight}px` : '0px',
                    }}
                >
                    <p className={styles.text}>
                        Vitaliv Club members can choose from a <span className={styles.bold}>wide range of
                        premium quality Vitaliv&apos;s dietary supplements, at fair price,</span> to support your health
                        in <span className={styles.bold}>all the major areas.</span>
                    </p>
                    <p className={styles.text}>
                        Support the <span className={styles.bold}>whole family&apos;s health</span> by purchasing all
                        at once. Use our <span className={styles.bold}>Cashback system.</span> This makes it easy to
                        budget <span className={styles.bold}>with significant savings.</span>
                    </p>
                    <p className={styles.text}>
                        When you shop with us, you can choose any products for yourself or your loved
                        ones without limitations and place as many packs as you want in your cart.<br/>
                        When you’re ready for a refill, simply place your next order. It is completely
                        within your control.
                    </p>
                    <p className={styles.text}>
                        Please note that Vitaliv Club is not a subscription and doesn&apos;t include any automatic orders
                        or payments. We will send you only those products which you have ordered.
                    </p>
                </div>
                <div
                    className={`${styles.buttonWrapper} ${
                        open === 11 ? styles.clicked : null
                    } ${(openMore ? null : styles.buttonOpenMore)}`}
                    onClick={() => onOpenHandler(11)}
                >
                    <p className={styles.buttonText}>
                        I already follow a balanced diet. Do I risk overdosing on nutrition if I also take supplements?
                    </p>
                    <div
                        className={`${styles.button} ${open === 11 && styles.clicked}`}
                    ></div>
                </div>
                <div
                    className={`${styles.panel} ${open === 11 ? styles.opened : ''}`}
                    style={{
                        height: open === 11 ? `${scrollHeight}px` : '0px',
                    }}
                >
                    <p className={styles.text}>
                        If you are still concerned, please consult your doctor.
                    </p>
                </div>
                <div
                    className={`${styles.buttonWrapper} ${
                        open === 13 ? styles.clicked : null
                    } ${(openMore ? null : styles.buttonOpenMore)}`}
                    onClick={() => onOpenHandler(13)}
                >
                    <p className={styles.buttonText}>
                        When will I experience the effect?
                    </p>
                    <div
                        className={`${styles.button} ${open === 13 && styles.clicked}`}
                    ></div>
                </div>
                <div
                    className={`${styles.panel} ${open === 13 ? styles.opened : ''}`}
                    style={{
                        height: open === 13 ? `${scrollHeight}px` : '0px',
                    }}
                >
                    <p className={styles.text}>
                        Some people will notice a fast effect, while others may have to wait several
                        months to see results.<br/>
                        Just remember: dietary supplements can never replace a healthy and active lifestyle,
                        but it is a good supplement for your diet.
                    </p>
                </div>
                <div
                    className={`${styles.buttonWrapper} ${
                        open === 15 ? styles.clicked : null
                    } ${(openMore ? null : styles.buttonOpenMore)}`}
                    onClick={() => onOpenHandler(15)}
                >
                    <p className={styles.buttonText}>
                        Are there any side effects?
                    </p>
                    <div
                        className={`${styles.button} ${open === 15 && styles.clicked}`}
                    ></div>
                </div>
                <div
                    className={`${styles.panel} ${open === 15 ? styles.opened : ''}`}
                    style={{
                        height: open === 15 ? `${scrollHeight}px` : '0px',
                    }}
                >
                    <p className={styles.text}>
                        The ingredients in our products are high quality and do not differ from those found in
                        food, and the <span className={styles.bold}>chance of side effects is therefore minimal.</span>
                    </p>
                </div>
                <div
                    className={`${styles.buttonWrapper} ${
                        open === 17 ? styles.clicked : null
                    } ${(openMore ? null : styles.buttonOpenMore)}`}
                    onClick={() => onOpenHandler(17)}
                >
                    <p className={styles.buttonText}>
                        How long should I take dietary supplements?
                    </p>
                    <div
                        className={`${styles.button} ${open === 17 && styles.clicked}`}
                    ></div>
                </div>
                <div
                    className={`${styles.panel} ${open === 17 ? styles.opened : ''}`}
                    style={{
                        height: open === 17 ? `${scrollHeight}px` : '0px',
                    }}
                >
                    <p className={styles.text}>
                        Our products are not a medicine or a drug. They are nutritional supplements.
                    </p>
                    <p className={styles.text}>
                        Nutritionists say that it usually takes from 3 months to a year to provide your
                        body with enough useful microelements. The time it takes varies from person to person.
                    </p>
                    <p className={styles.text}>
                        <span className={styles.bold}>Why? The reason is the so-called plateau effect.</span><br/>
                        Once you start taking any dietary supplement, the accumulation of nutrients grows
                        exponentially until it reaches a plateau. It is a steady-state effect where rates
                        of nutrients&apos; absorption and loss are balanced.<br/>
                        <span className={styles.bold}>Regular intake of high-quality dietary supplements</span> will
                        help preserve the level of nutrient concentration, and you may experience normal body function.
                        If you stop using food supplements, this level starts to gradually decline. Sooner or later,
                        it will return to its initial state.
                    </p>
                </div>
            </div>


            {errorPage
                ? <div className={styles.buttonContainer}>
                    <button className={openMore ? styles.buttonOpenMore :styles.buttonOpen}
                            onClick={() => setOpenMore(true)}
                    >
                        {width > 1024 ? <span>See all answers to questions</span> : <span>See all answers</span>}

                    </button>
                </div>
                : <div className={styles.buttonContainer}>
                    <button className={openMore ? styles.buttonOpenMore :styles.buttonOpen}
                            onClick={() => setOpenMore(true)}
                    >
                        {width > 1024 ? <span>See all answers to questions</span> : <span>See all answers</span>}
                    </button>
                </div>
            }


        </section>
    )
}

export default FaqBlock